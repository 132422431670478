<template>
  <b-tab>
    <template #title>
      <div class="d-flex justify-content-between align-items-center w-100">
        <div class="d-flex align-items-center">
          <b-form-checkbox
            v-model="allSelected"
            :indeterminate="indeterminate"
            aria-describedby="flavours"
            aria-controls="flavours"
            inline
            @change="toggleAll"
            @click.stop.native
          />
          <span class="text-capitalize">{{ categoryName }}</span>
        </div>
        <div class="ml-2">
          ({{ selectedPermissionsLength }}/{{ categoryPermissions.length }})
        </div>
      </div>
    </template>
    <b-form-input
      v-model="search"
      placeholder="Search"
      class="permission-search"
    />
    <b-form-group
      :label="`${categoryName} Permissions`"
      label-class="text-capitalize font-weight-bold"
    >
      <template v-for="(categoryPermission,index) in filteredCategoryPermissions">
        <b-form-checkbox
          :key="index"
          v-model="categoryPermission.value"
          class="mb-1"
        >
          {{ categoryPermission.title }}
        </b-form-checkbox>
      </template>
    </b-form-group>
  </b-tab>
</template>

<script>
import {
  BTab,
  BFormInput,
  BFormCheckbox,
  BFormGroup,
} from 'bootstrap-vue'

export default {
  name: 'PermissionCategoryTab',
  components: {
    BTab,
    BFormInput,
    BFormCheckbox,
    BFormGroup,
  },
  props: {
    categoryPermissions: {
      type: Array,
      required: true,
    },
    categoryName: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      allSelected: false,
      indeterminate: false,
      search: '',
      filteredCategoryPermissions: this.categoryPermissions,
    }
  },
  computed: {
    selectedPermissionsLength() {
      return this.categoryPermissions.filter(item => item.value).length
    },
  },
  watch: {
    categoryPermissions: {
      deep: true,
      handler(newValue) {
        this.checkPermissionsValues(newValue)
      },
    },
    search(val) {
      if (val) {
        this.filteredCategoryPermissions = this.categoryPermissions.filter(item => item.title.toLowerCase().includes(val))
      } else {
        this.filteredCategoryPermissions = this.categoryPermissions
      }
    },
  },
  created() {
    this.checkPermissionsValues(this.categoryPermissions)
  },
  methods: {
    toggleAll(val) {
      if (val) {
        this.categoryPermissions.forEach(item => {
          // eslint-disable-next-line no-param-reassign
          item.value = true
        })
      } else {
        this.categoryPermissions.forEach(item => {
          // eslint-disable-next-line no-param-reassign
          item.value = false
        })
      }
    },
    checkPermissionsValues(value) {
      const permissionsValues = value.map(item => item.value)
      if (permissionsValues.every(item => item === false)) {
        this.indeterminate = false
        this.allSelected = false
      } else if (permissionsValues.every(item => item === true)) {
        this.indeterminate = false
        this.allSelected = true
      } else {
        this.indeterminate = true
        this.allSelected = false
      }
    },
  },
}
</script>

<style >
.permission-search {
  width: 250px;
  margin-bottom: 2rem;
}
</style>
