<template>
  <div>
    <b-overlay
      id="overlay-background"
      :show="isLoading"
      variant="white"
      opacity="1"
      rounded="sm"
    >
      <b-row>
        <b-col cols="12">
          <h2>{{ role.display_name }}</h2>
        </b-col>
      </b-row>
      <b-card>
        <h4>Permissions</h4>
        <b-tabs
          pills
          vertical
          nav-class="text-left"
          content-class="pl-5"
        >
          <permission-category-tab
            v-for="(categoryPermissions, categoryName) in role.permissions"
            :key="categoryName"
            :category-name="categoryName"
            :category-permissions="categoryPermissions"
          />
        </b-tabs>

        <b-row>
          <b-col
            cols="12"
            class="w-100 d-flex justify-content-end"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mt-2 mr-1"
              @click="updateRolePermissions"
            >
              Save changes
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-secondary"
              class="mt-2"
              :to="{ name: 'admin-roles-list'}"
            >
              Cancel
            </b-button>
          </b-col>
        </b-row>
      </b-card>

    </b-overlay>

  </div>

</template>

<script>
import {
  BOverlay,
  BCard,
  BRow,
  BCol,
  BTabs,
  BButton,
} from 'bootstrap-vue'

import Ripple from 'vue-ripple-directive'
import useRoleEdit from '@/views/admin/roles/role-edit/useRoleEdit'
import PermissionCategoryTab from '@/views/admin/roles/role-edit/components/PermissionCategoryTab.vue'

export default {
  name: 'RoleEdit',
  components: {
    BOverlay,
    BCard,
    BRow,
    BCol,
    BTabs,
    BButton,
    PermissionCategoryTab,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      isLoading: false,
      role: {
        display_name: '',
        permissions: {},
      },
    }
  },
  setup() {
    const {
      fetchRole,
      updateRole,
    } = useRoleEdit()

    return {
      fetchRole,
      updateRole,
    }
  },
  created() {
    this.setRoleData()
  },
  methods: {
    async setRoleData() {
      try {
        this.isLoading = true
        const roleId = this.$router.currentRoute.params.id
        this.role = await this.fetchRole(roleId)
      } finally {
        this.isLoading = false
      }
    },
    async updateRolePermissions() {
      await this.updateRole(this.role)
    },
  },
}
</script>

<style>

</style>
