import axios from '@axios'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useRoleEdit() {
  // Use toast
  const toast = useToast()

  const fetchRole = ID => axios
    .get(`auth/admin-roles/${ID}/edit`)
    .then(response => response.data.data)
    .catch(() => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error fetching Role',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    })

  const updateRole = queryParams => axios
    .put(`auth/admin-roles/${queryParams.id}`, queryParams)
    .then(response => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Role Permissions was updated',
          icon: 'ThumbsUpIcon',
          variant: 'info',
        },
      })
      return response.data.data
    })
    .catch(() => toast({
      component: ToastificationContent,
      props: {
        title: 'Error updating Role Permissions',
        icon: 'AlertTriangleIcon',
        variant: 'danger',
      },
    }))

  return {
    fetchRole,
    updateRole,
  }
}
